@import url("https://fonts.googleapis.com/css2?family=Roboto&family=Rubik:wght@400;500&display=swap");

body {
  font-size: 16px;
}

h1,
h3 {
  font-family: "Rubik", sans-serif;
  padding: 0px;
  margin: 0px;
  width: fit-content;
}

h1 {
  font-size: 3rem;
  font-weight: 500;
}

h3 {
  font-size: 1.5rem;
  font-weight: 400;
}

p,
label,
select {
  font-family: "roboto", sans-serif;
  font-size: 1rem;
  font-weight: 400;
}

.header {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.page-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.app_container {
  margin-top: 30px;
  width: 750px;
}

.app_selector {
  display: flex;
  gap: 15px;
  align-items: center;
}

.app_company-selector {
  width: 300px;
}
.app_route-selector {
  width: 300px;
}
.app_stop-selector {
  width: 750px;
}
